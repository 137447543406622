import React from "react"
import styled, { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  body {
        background: #EEE;
        color: #FFF;
	margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
       }
  html {
    box-sizing: border-box;
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
`
const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  height: 40px;
  padding: 0 5%;
  background: blue;
  color: white;
  width: 100vw;
  display: flex;
  justify-content: space-between;
`
const Logo = styled.div``
const MainNav = styled.nav`
  > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    > li {
      padding: 10px;
      display: inline-block;
    }
  }
`
const Main = styled.main`
  margin: 4rem 0;
  display: grid;
  justify-content: center;
  grid-template-columns: 180px 500px 308px;
  grid-template-areas: "nav feed services";
  grid-column-gap: 12px;
  height: calc(100vh-40px);
  @media (min-width: 900px) {
    padding-right: 200px;
  }
`
const NewsFeed = styled.section`
  grid-area: feed;
  height: calc(100vh - 40px);
  overflow-y: scroll;
`
const Post = styled.article`
  background: white;
  border-radius: 5px;
  padding: 10px;
  overflow: hidden;
  margin: 1rem 0;
`
const PostHeader = styled.div`
  display: flex;
  align-items: center;
`
const Avatar = styled.img`
  border-radius: 50%;
`
const PostInfo = styled.div`
  margin-left: 10px;
  > a,
  span {
    display: block;
  }
`
const PostContent = styled.div`
  > img {
    width: 100%;
  }
`
const PostEngage = styled.div`
padding: 10px 0;`
const Comment = styled.div`
  background: #eee;
  display: flex;
  padding: 10px;
  margin: 0 -10px -10px;
  border-top: solid 1-px #ccc;
  > input {
    width: 100%;
    margin-left: 10px;
    border-radius: 20px;
    border: solid 1px #ccc;
    padding: 10px 20px;
    height: 50px;
  }
`
const SideNav = styled.aside`
  grid-area: nav;
`
const Services = styled.aside`
  grid-area: services;
`

const Page = ({ location }) => (
  <>
    <Header>
      <Logo>Logo</Logo>
      <MainNav>
        <ul>
          <li>Home</li>
          <li>Icon</li>
          <li>Icon 2</li>
        </ul>
      </MainNav>
    </Header>
    <Main>
      <NewsFeed>
        <Post>
          <PostHeader>
            <Avatar src="http://via.placeholder.com/50x50" />
            <PostInfo>
              <a href="#">User Name</a>
              <span>Just Now</span>
            </PostInfo>
          </PostHeader>
          <PostContent>
            <img src="http://via.placeholder.com/490x400" />
          </PostContent>
          <PostEngage>
            <a href="#">Like</a>
            <a href="#">Comment</a>
            <a href="#">Share</a>
          </PostEngage>
          <Comment>
            <Avatar src="http://via.placeholder.com/50x50" />
            <input type="text" placeholder="Write a comment.." />
          </Comment>
        </Post>
        <Post>
          <PostHeader>
            <Avatar src="http://via.placeholder.com/50x50" />
            <PostInfo>
              <a href="#">User Name</a>
              <span>Just Now</span>
            </PostInfo>
          </PostHeader>
          <PostContent>
            <img src="http://via.placeholder.com/490x400" />
          </PostContent>
          <PostEngage>
            <a href="#">Like</a>
            <a href="#">Comment</a>
            <a href="#">Share</a>
          </PostEngage>
          <Comment>
            <Avatar src="http://via.placeholder.com/50x50" />
            <input type="text" placeholder="Write a comment.." />
          </Comment>
        </Post>
        <Post>
          <PostHeader>
            <Avatar src="http://via.placeholder.com/50x50" />
            <PostInfo>
              <a href="#">User Name</a>
              <span>Just Now</span>
            </PostInfo>
          </PostHeader>
          <PostContent>
            <img src="http://via.placeholder.com/490x400" />
          </PostContent>
          <PostEngage>
            <a href="#">Like</a>
            <a href="#">Comment</a>
            <a href="#">Share</a>
          </PostEngage>
          <Comment>
            <Avatar src="http://via.placeholder.com/50x50" />
            <input type="text" placeholder="Write a comment.." />
          </Comment>
        </Post>
      </NewsFeed>
      <SideNav />
      <Services />
    </Main>
  </>
)

export default Page
